import './bootstrap';

// Import Bootstrap JS
import 'bootstrap';
import AOS from 'aos';

// Wait for document ready
document.addEventListener('DOMContentLoaded', function() {
    // Small delay to ensure everything is loaded
    setTimeout(() => {
        AOS.init({
            duration: 800,
            once: true,
            offset: 100,
            disable: 'mobile' // vypne na mobiloch pre lepší výkon
        });

        // Refresh AOS after dynamic content loads
        document.addEventListener('lazyloaded', function() {
            AOS.refresh();
        });
    }, 100);
});

// Animácia pre face-straight
document.addEventListener('DOMContentLoaded', function() {
    const face = document.querySelector('.face-straight');
    if (face) {
        face.style.animationPlayState = 'running';
    }
});

// Pridáme hover efekt pre smile ikonku
document.addEventListener('DOMContentLoaded', function() {
    const smileIcons = document.querySelectorAll('.smile-icon');

    smileIcons.forEach(icon => {
        icon.addEventListener('mouseenter', function() {
            const i = this.querySelector('i');
            i.style.animationPlayState = 'running';
        });

        icon.addEventListener('mouseleave', function() {
            const i = this.querySelector('i');
            i.style.animationPlayState = 'paused';
        });
    });
});

// Hamburger menu
document.addEventListener('DOMContentLoaded', function() {
    const hamburger = document.querySelector('.hamburger');
    const mobileMenu = document.querySelector('.mobile-menu');

    if (hamburger && mobileMenu) {
        hamburger.addEventListener('click', function() {
            this.classList.toggle('is-active');
            mobileMenu.classList.toggle('is-active');

            // Update ARIA expanded state
            const isExpanded = this.classList.contains('is-active');
            this.setAttribute('aria-expanded', isExpanded);
        });
    }
});

// Your custom JS below
